<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-b">提现管理</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-upload2" class="s-btn-exp">导出</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" id="leSearch">
                <le-input-icon-search label="商户名称" v-model="pageParam.params.searchKey" placeholder="请输入" />
                <le-select-local-search label="提现状态" multiple collapse v-model="pageParam.params.states" :options="optionsProject" />
                <le-select-local-search label="提现钱包" v-model="pageParam.params.payPlatform" :options="payPlatformDic" />
                <le-date-range 
                    label="日期" 
                    ref="dateRange" 
                    :minDate.sync="pageParam.params.startDay" 
                    :maxDate.sync="pageParam.params.endDay" 
                    valueFormat="yyyy-MM-dd HH:mm:ss" 
                    :defaultTime="['00:00:00','23:59:59']"
                    :clearable='true' />
            </le-search-form>
            <le-pagview ref="withdrawallist" @setData="setTableData" :pageParam="pageParam" :total.sync="total" :isFixed="true" :tableRef="$refs.withdrawallistTable">
                <el-table ref="withdrawallistTable" :data="tableData" :highlight-current-row="true" v-sticky="{ top: 0, parent:'.el-card__body' }">
                    <el-table-column prop="companyName" label="商户名称" min-width="150"></el-table-column>
                    <el-table-column prop="companyType" label="商户类型" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.companyType | initDic(companyTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="applyUserName" label="申请人" ></el-table-column>
                    <el-table-column prop="createTimeText" label="申请时间" min-width="120"></el-table-column>
                    <el-table-column prop="payoutMethodText" label="提现方式" min-width="120"></el-table-column>
                    <el-table-column prop="amount"  label="提现金额(元)" min-width="120">
                        <template slot-scope="{ row }">
                            <span> {{'￥'+config.numFormat(row.amount)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="审核状态"  min-width="120">
                        <template slot-scope="{ row }">
                            <span :class="'stateClass'+row.state">{{ row.state | initDic(optionsProject) }}</span>
                            <!-- 0:待审核,1:待付款,2:成功,3:关闭,4:异常 5:审核不通过 -->
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="操作" width="80" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="详情" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16 mgr20"
                                    @click="toWithdrawalDetail(scope.row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
                <template slot="footerLabel" slot-scope="scope">
                    <div class="a-flex-rfsc" v-if="scope.val != -1">
                        <span class="a-fs-12 a-c-normal">共</span>
                        <span class="a-fs-16 a-c-master a-fw-b a-plr-7">{{ total }}</span>
                        <span class="a-fs-12 a-c-normal">名员工</span>
                    </div>
                </template>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
import config from '../../../src/utils/util'
export default {
    filters:{
        payout(val){
            let text=''
            switch (val) {
                case '0':
                    text='银行转账'
                    break;
                case '1':
                    text='付款至微信零钱'
                    break;
                case '2':
                    text='富友支付'
                    break;
                case '3':
                    text='3通联支付'
                    break;            
                default:
                    text="其他"
                    break;
            }
        }
    },
    data() {
        return {
            config: config,//公共方法集

            pageParam: {
                url: this.$Config.apiUrl.getWithdrawManagerList,
                method: "post",
                params: {
                    searchKey: "",//加盟商名称
                    states:[],//
                    payPlatform: '',
                    "endDay": this.$getDay.getTodayBeforeDays(0) + ' 23:59:59',
                    "startDay": this.$getDay.getTodayBeforeDays(30) + ' 00:00:00'
                },
                freshCtrl: 1,
            },
            optionsProject:[],
            payPlatformDic: [],
            tableData: [],//数据列表
            total:0,
            companyTypeDic: []
        }
    },
    created() {
        this.$getDic('companyType','select').then(res=>{ 
            this.companyTypeDic = res
        })
        this.$getDic('withdrawState','select').then(res=>{ 
            this.optionsProject = res
        })
        this.$getDic('payPlatform','select').then(res=>{ 
            this.payPlatformDic = res
        })
    },
    activated () {
        this.pageParam.freshCtrl++;
    },
    methods: {
        //获取列表
        setTableData(data) {
            this.tableData = data;
        },
        //确认筛选
        handlerSearch() {
            this.$refs['withdrawallist'].pageNum = 1
            this.pageParam.freshCtrl++;
        },
        //重置筛选表单
        handlerRest() {
            this.pageParam.params = {
                searchKey: "",//加盟商名称
                states:[],//
                payPlatform: '',
                "endDay": this.$getDay.getTodayBeforeDays(0) + ' 23:59:59',
                "startDay": this.$getDay.getTodayBeforeDays(30) + ' 00:00:00'
            };
            this.$refs['dateRange'].value1 = [this.$getDay.getTodayBeforeDays(30) + ' 00:00:00', this.$getDay.getTodayBeforeDays(0) + ' 23:59:59']
            this.handlerSearch()
        },
        companyBack() { },
        //跳转详情
        toWithdrawalDetail(datas) {
            this.$router.push({
               path: `/withdrawal/withdrawal-detail?code=${datas.id}`
            })
        },
    }
}
</script>

<style lang="scss" scoped>
    .mgl150 {
        margin-left: 150px;
    }
    .stateClass0{
        display: inline-block;
        background: #E6F1FF;
        color:#007AFF;
        font-size: 14px;
        padding:2px 8px;
        border-radius: 4px;
    }
    .stateClass1{
        display: inline-block;
        background: #E6F1FF;
        color:#007AFF;
        font-size: 14px;
        padding:2px 8px;
        border-radius: 4px;
    }
    .stateClass2{
        display: inline-block;
        background: #E6FFE6;
        color:#29CC29;
        font-size: 14px;
        padding:2px 8px;
        border-radius: 4px;
    }
    .stateClass3{
        display: inline-block;
        background: #EBEBEB;
        color:#606366;
        font-size: 14px;
        padding:2px 8px;
        border-radius: 4px;
    }
    .stateClass4{
        display: inline-block;
        background: #FFEDED;
        color:#FF3B30 ;
        font-size: 14px;
        padding:2px 8px;
        border-radius: 4px;
    }
    .stateClass5{
        display: inline-block;
        background: #FFEDED;
        color:#FF3B30 ;
        font-size: 14px;
        padding:2px 8px;
        border-radius: 4px;
    }
    .stateClass6{
        display: inline-block;
        background: rgb(253,246,236);
        color:#E6A23C ;
        font-size: 14px;
        padding:2px 8px;
        border-radius: 4px;
    }
    .stateClass7{
        display: inline-block;
        background: rgb(253,246,236);
        color:#E6A23C ;
        font-size: 14px;
        padding:2px 8px;
        border-radius: 4px;
    }
    .stateClass8{
        display: inline-block;
        background: rgb(253,246,236);
        color:#E6A23C ;
        font-size: 14px;
        padding:2px 8px;
        border-radius: 4px;
    }
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
</style>